.articles-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.articles-container .article-box {
  flex: 0 0 25%;
  padding: 5px;
}
.articles-container .article-box .article-inner-box {
  position: relative;
  overflow: hidden;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.articles-container .article-box .article-inner-box img {
  -o-object-fit: cover;
     object-fit: cover;
  min-height: 240px;
  transition: transform 0.6s;
}
.articles-container .article-box .article-inner-box h4 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #f5f5f5;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  transition: transform 0.5s;
  z-index: 2;
  margin: 0;
}
.articles-container .article-box .article-inner-box p {
  padding: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(245, 245, 245, 0.9333333333);
  transform: translateY(100%);
  transition: transform 0.5s;
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.articles-container .article-box .article-inner-box a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
  color: #f5f5f5;
  z-index: 3;
}
.articles-container .article-box .article-inner-box:hover p {
  transform: translateY(0%);
}
.articles-container .article-box .article-inner-box:hover h4 {
  transform: translateY(100%);
}

@media screen and (max-width: 991px) {
  .articles-container .article-box {
    flex: 0 0 33.333%;
  }
  .articles-container .article-box .article-inner-box {
    height: 100%;
  }
  .articles-container .article-box .article-inner-box h4 {
    bottom: unset;
    transform: translateY(-100%);
  }
  .articles-container .article-box .article-inner-box p {
    padding: 10px;
    position: static;
    transform: translateY(0%);
  }
  .articles-container .article-box .article-inner-box:hover p {
    transform: translateY(0%);
  }
  .articles-container .article-box .article-inner-box:hover h4 {
    transform: translateY(-100%);
  }
}
@media screen and (max-width: 767px) {
  .articles-container .article-box {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 575px) {
  .articles-container .article-box {
    flex: 0 0 100%;
  }
}/*# sourceMappingURL=articles.css.map */