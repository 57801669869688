.articles-container
    display: flex
    flex-wrap: wrap
    margin-left: -5px
    margin-right: -5px
    
    .article-box
        flex: 0 0 25%
        padding: 5px

        .article-inner-box
            position: relative
            overflow: hidden
            box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)
            border-radius: 4px

            img
                object-fit: cover
                min-height: 240px
                transition: transform 0.6s

            h4
                position: absolute
                bottom: 0px
                left: 0px
                right: 0px
                padding: 12px
                background-color: rgba(0,0,0,.7)
                color: #f5f5f5
                text-align: center
                font-size: 16px
                font-weight: normal
                transition: transform 0.5s
                z-index: 2
                margin: 0

            p
                padding: 20px
                text-align: center
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0
                background: #f5f5f5ee
                transform: translateY(100%)
                transition: transform 0.5s
                margin: 0
                font-size: 16px
                display: flex
                justify-content: center
                align-items: center
                z-index: 1

            a
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                font-size: 0
                color: #f5f5f5
                z-index: 3

        .article-inner-box:hover
            // img
            //     transform: scale(1.1)
            p
                transform: translateY(0%)
            h4 
                transform: translateY(100%)

@media screen and (max-width: 991px)
    .articles-container
        .article-box
            flex: 0 0 33.333%
            .article-inner-box
                height: 100%
                h4
                    bottom: unset
                    transform: translateY(-100%)
                p
                    padding: 10px
                    position: static
                    transform: translateY(0%)
            .article-inner-box:hover
                p
                    transform: translateY(0%)
                h4 
                    transform: translateY(-100%)

@media screen and (max-width: 767px)
    .articles-container
        .article-box
            flex: 0 0 50%

@media screen and (max-width: 575px)
    .articles-container
        .article-box
            flex: 0 0 100%