#contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#contact-form input, #contact-form textarea {
  max-width: 700px;
  width: 100%;
  padding: 15px;
  border: 0px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
  font-family: "Liberation Serif", Cambria, Times New Roman, serif;
  font-size: 16px;
  margin-bottom: 15px;
  border-radius: 4px;
}
#contact-form textarea {
  height: 300px;
  resize: none;
}
#contact-form button {
  -webkit-appearance: none;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

.contact-alert-succes, .contact-alert-error {
  max-width: 700px;
  width: 100%;
  margin: 30px auto;
  padding: 10px;
  background-color: #00c600;
  color: #f5f5f5;
  text-align: center;
  border-radius: 4px;
}

.contact-alert-error {
  background-color: #e60000;
}

.contact-page h2:nth-of-type(2) {
  margin-top: 30px;
  margin-bottom: 15px;
}/*# sourceMappingURL=contact.css.map */