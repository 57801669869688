article
    max-width: 900px
    margin: 0 auto

    img:first-of-type
        width: 100%
        object-fit: cover
        max-height: calc(900px * 7 / 16)
        margin-bottom: 20px

    p, p.txt
        text-align: justify
        text-indent: 1.5em
        line-height: 150%
        margin-bottom: 10px

    p.txt
        text-indent: 0

    .author
        text-indent: 0
        font-style: italic
        text-align: right

    .footnote
        text-indent: 0
        font-size: 14px
        line-height: 130%
        margin-bottom: 10px

    .date    
        text-indent: 0
        font-size: 14px
        color: #696969
        margin-bottom: 10px