.select-title
    position: relative
    padding: 15px 60px 15px 15px
    margin-bottom: 10px
    width: 100%
    background-color: #ededed
    color: #4d4d4d
    // text-align: center
    font-size: 22px
    font-weight: bold
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15)
    transition: background-color 0.5s
    border-radius: 4px
    img
        position: absolute
        top: 50%
        right: 14px
        width: 24px
        height: 24px
        transform: translateY(-50%) rotate(90deg)
        transition: transform 0.5s
        filter: invert(1)
        opacity: 0.7
        pointer-events: none
.select-title.active
    img
        transform: translateY(-50%) rotate(270deg)

.select-title:hover
    background-color: #e8e8e8
    cursor: pointer

.select-title:first-of-type
    margin-top: 0px

.select-container
    padding: 4px
    overflow-y: hidden

.select-container.active
    height: auto

.select-container
    ul
        margin: 0px 0px 0px 1.5em
        padding: 0 0 19px
        a
            text-decoration: underline