main
    .home-slider
        height: 100vh
        overflow: hidden
        position: relative

        .home-slider-img
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            width: 100%
            height: 100vh
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            transition: opacity .8s linear
            opacity: 0

        .home-slider-img.active
            opacity: 1

        .home-slider-img.anim  
            animation: homeSlideAnim 6s linear

        @keyframes homeSlideAnim
            0%
                transform: scale(1) rotate(-0.1deg)
            100%
                transform: scale(1.1) rotate(-0.1deg)

        .home-slider-overlay
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            width: 100%
            height: 100vh
            background: #f5f5f5aa

        .home-slider-psalm
            position: absolute
            top: 50%
            left: 0
            right: 0
            font-family: 'Noto Serif'
            font-size: 52px
            text-align: center
            line-height: 0.7
            color: #4d4d4d
            text-shadow: 0 0 10px #fff
            padding: 15px
            span
                font-size: 22px

        .home-slider-arrow
            position: absolute
            left: 50%
            bottom: 15px
            transform: translateX(-50%)
            width: 34px
            height: 30px
            background-color: #4d4d4d
            border-radius: 4px
            cursor: pointer

            img
                position: absolute
                top: 50%
                left: 50%
                width: 14px
                height: 14px
                transform: translate(-50%, -50%) rotate(90deg)
                animation: arrowAnim 2.5s linear infinite

            @keyframes arrowAnim 
                0%
                    transform: translate(-50%, -50%) rotate(90deg)
                25%
                    transform: translate(-50%, -30%) rotate(90deg)
                75%
                    transform: translate(-50%, -70%) rotate(90deg)
                100%
                    transform: translate(-50%, -50%) rotate(90deg)
                

    .bottom-section
        height: 100vh

    .home-videos
        h2
            text-align: left
        .container 
            display: flex

            .home-videos-text
                flex: 0 0 30%
                padding-right: 15px
                display: flex
                flex-direction: column
                justify-content: space-between

            .home-videos-boxes
                flex: 0 0 70%
                padding-left: 15px
                display: flex
                flex-wrap: wrap
                margin-bottom: -5px

                .card
                    color: #f5f5f5
                    flex: 0 0 33.333%
                    padding: 5px

                    p
                        font-size: 16px

    .card
        position: relative
        width: 100%
        border: 0

        .card-box
            overflow: hidden
            position: relative
            box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)
            border-radius: 4px

            img
                width: 100%
                object-fit: cover
                min-height: 180px
                transition: transform 0.6s

            p
                position: absolute
                bottom: 0px
                left: 0px
                right: 0px
                padding: 12px
                background-color: rgba(0,0,0,.7)
                text-align: center
                margin: 0
        
            a
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                font-size: 0
                color: #f5f5f5

        .card-box:hover
            img
                transform: scale(1.1)

    .home-notes
        background-color: #fff

        .container
            display: flex

            .home-notes-container
                display: flex
                flex-wrap: wrap

                .home-notes-box 
                    flex: 0 0 50%
                    position: relative
                    padding: 5px

                    .home-notes-mask
                        position: relative
                        overflow: hidden
                        box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15)
                        border-radius: 4px

                        img
                            object-fit: cover
                            object-position: top
                            width: 100%
                            height: 220px
                            transition: opacity 0.6s, object-position 0.6s
                            opacity: 0.1

                        .overlay
                            position: absolute
                            top: 0
                            bottom: 0
                            left: 0
                            right: 0
                            background: linear-gradient(45deg, rgba(77,77,77,0.1) 0%, rgba(255,255,255,0) 30%, rgba(77,77,77,0.1) 100%)
                            transition: opacity 0.6s
                            opacity: 0.2

                    .desc
                        position: absolute
                        top: 0
                        bottom: 0
                        left: 0
                        right: 0
                        display: flex
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        font-weight: bold
                        transition: opacity 0.6s

                        span
                            font-weight: normal

                .home-notes-box:hover
                    .desc 
                        opacity: 0
                    .home-notes-mask
                        .overlay
                            opacity: 1
                        img
                            opacity: 1
                            transition: opacity 0.6s, object-position 8s
                            object-position: bottom

        .container > div
            flex: 0 0 50%

        .container > div:nth-of-type(2)
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding: 60px

            p
                text-align: center

    .home-articles
        .newest-articles
            display: flex
            margin-left: -5px
            margin-right: -5px
            
            .article-box
                flex: 0 0 25%
                padding: 0 5px

                .article-inner-box
                    position: relative
                    overflow: hidden
                    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)
                    border-radius: 4px

                    img
                        object-fit: cover
                        min-height: 240px
                        transition: transform 0.6s

                    h4
                        position: absolute
                        bottom: 0px
                        left: 0px
                        right: 0px
                        padding: 12px
                        background-color: rgba(0,0,0,.7)
                        color: #f5f5f5
                        text-align: center
                        font-size: 16px
                        font-weight: normal
                        margin: 0

                    a
                        position: absolute
                        top: 0
                        bottom: 0
                        left: 0
                        right: 0
                        font-size: 0
                        color: #f5f5f5

                .article-inner-box:hover
                    img
                        transform: scale(1.1)

    .home-library
        position: relative
        padding: 120px 0
        background-color: #fff
        background-position: center
        background-size: cover
        color: #f5f5f5
        text-align: center

        .overlay
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            background-color: rgba(0, 0, 0, 0.7)
            z-index: 0

        .container
            position: relative
            z-index: 1

        h2, p
            color: #f5f5f5

        .btn-box
            text-align: center

        .btn
            background-color: #f5f5f5
            color: #4d4d4d
            img
                filter: invert(0.9)
        .btn:hover
            background-color: #d7d7d7

@media screen and (max-width: 991px)
    main
        .home-slider
            .home-slider-psalm
                top: 50%
                font-size: 38px
                line-height: 1
                transform: translateY(-50%)
                span
                    font-size: 18px

        .home-videos
            text-align: center
            position: relative
            padding-bottom: calc(40px + 58px)

            h2
                text-align: center
            .container
                flex-direction: column
                .home-videos-boxes
                    padding-top: 20px
                    padding-left: 0
                    margin: 0 -4px
                    .card
                        flex: 0 0 33.333%
                .home-videos-text
                    padding-right: 0
            
            .btn-container
                position: absolute
                bottom: 40px
                left: 15px
                right: 15px
        
        .home-notes
            position: relative
            padding-bottom: calc(40px + 58px)
            .container
                flex-direction: column-reverse
                .home-notes-box .home-notes-mask img
                    animation: notesImgPreview 20s ease-in-out infinite  
                @keyframes notesImgPreview
                    0%
                        opacity: 0.1
                        object-position: top
                    17%
                        opacity: 0.1
                        object-position: top
                    20%
                        opacity: 1
                        object-position: top
                    58%
                        opacity: 1
                        object-position: bottom
                    97%
                        opacity: 1
                        object-position: top
                    100%
                        opacity: 0.1
                        object-position: top
                .home-notes-box .desc
                    animation: notesDescPreview 20s ease-in-out infinite  
                @keyframes notesDescPreview 
                    0%
                        opacity: 1
                    17%
                        opacity: 1
                    20%
                        opacity: 0
                    97%
                        opacity: 0
                    100%
                        opacity: 1
            .container > div:nth-of-type(2)
                padding: 0px
                .btn-container
                    position: absolute
                    bottom: 40px
                    left: 15px
                    right: 15px

        .home-articles
            .container
                .newest-articles
                    .article-box
                        flex: 0 0 33.333%
                    .article-box:nth-of-type(n + 4)
                        display: none              

@media screen and (max-width: 767px)
    main
        .home-videos
            .container
                .home-videos-boxes
                    .card
                        flex: 0 0 50%
                    .card:nth-of-type(n + 5)
                        display: none

        .home-articles
            .container
                .newest-articles
                    flex-wrap: wrap
                    .article-box
                        flex: 0 0 50%
                        padding: 5px
                    .article-box:nth-of-type(n + 4)
                        display: block
                    .article-box:nth-of-type(n + 5)
                        display: none

@media screen and (max-width: 575px)
    main
        .home-videos
            .container
                .home-videos-boxes
                    .card
                        flex: 0 0 100%
                    .card:nth-of-type(n + 4)
                        display: none
        
        .home-notes
            .container
                .home-notes-container
                    .home-notes-box
                        flex: 0 0 100%
                        .home-notes-mask
                            img
                                height: 280px
                    .home-notes-box:nth-of-type(n + 3)
                        display: none

        .home-articles
            .container
                .newest-articles
                    flex-wrap: wrap
                    .article-box
                        flex: 0 0 100%