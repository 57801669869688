article {
  max-width: 900px;
  margin: 0 auto;
}
article img:first-of-type {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 393.75px;
  margin-bottom: 20px;
}
article p, article p.txt {
  text-align: justify;
  text-indent: 1.5em;
  line-height: 150%;
  margin-bottom: 10px;
}
article p.txt {
  text-indent: 0;
}
article .author {
  text-indent: 0;
  font-style: italic;
  text-align: right;
}
article .footnote {
  text-indent: 0;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 10px;
}
article .date {
  text-indent: 0;
  font-size: 14px;
  color: #696969;
  margin-bottom: 10px;
}/*# sourceMappingURL=article.css.map */