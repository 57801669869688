*
    box-sizing: border-box
    padding: 0
    margin: 0

body
    background: #f5f5f5
    color: #4d4d4d
    font-size: 18px
    font-family: 'Liberation Serif', Cambria, Times New Roman, serif
    overflow-x: hidden

#root
    position: relative
    min-height: 100vh

a
    color: #4d4d4d
    text-decoration: none

a:hover
    color: #696969

img
    display: block
    max-width: 100%

p
    margin-bottom: 15px

.container
    margin: 0 auto
    width: 100%
    max-width: 1180px
    padding: 0 15px

.standard-padding
    padding: 60px 0px

.text-center
    text-align: center

.btn
    display: inline-block
    padding: 10px 50px 10px 20px
    margin-top: 30px
    background-color: #4d4d4d
    border-radius: 4px
    transition: background-color 0.3s
    position: relative
    color: #f5f5f5
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)

    img
        width: 14px
        height: 14px
        position: absolute
        top: 50%
        right: 20px
        transform: translate(0, -50%)
        transition: transform 0.5s

.btn:hover
    background-color: #696969
    color: #f5f5f5
    img
        transform: translate(50%, -50%)

h2
    font-size: 24px
    text-align: center
    margin-bottom: 30px
    // font-family: 'Noto Serif', serif

h4
    font-size: 20px
    font-weight: bold
    margin: 20px 0

.loading
    text-align: center
    flex: 0 0 100%
    img 
        display: block
        margin: 0 auto
        animation: loaderAnim 1.5s linear infinite
    @keyframes loaderAnim
        0% 
            transform: rotate(0deg)
        100%
            transform: rotate(360deg)
    &.loading-items
        position: absolute
        top: -10px
        bottom: -10px
        left: -10px
        right: -10px
        background-color: #f5f5f5bb
        display: flex
        justify-content: center
        align-items: center
        z-index: 2
    &.fullscreen-loading
        position: absolute
        height: 100vh
        width: 100vw
        display: flex
        justify-content: center
        align-items: center
        padding: 78px 15px 142px

header
    position: fixed
    top: 0
    left: 0
    right: 0
    width: 100%
    background-color: #4d4d4d
    display: flex
    justify-content: space-between
    align-items: center
    color: #fff 
    z-index: 100
        
    .logo
        display: block
        padding: 15px 15px 11px
        text-transform: uppercase
        font-family: 'Noto Serif', serif
        width: 260px
        color: #fff 
        font-size: 28px
        line-height: 0.7em
        letter-spacing: 4px
        text-decoration: none
        transition: opacity .3s
        
        .logo-description 
            text-shadow: 0px 0px 10px #fffa
            font-size: 11px
            color: #fff 
            letter-spacing: 6px
            line-height: 1em

    .logo:hover
        opacity: 0.8

    nav
        padding: 15px 15px 14px
        
        ul 
            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: center
            list-style-type: none
            margin: 0

            li
                a
                    display: inline-block
                    color: #f5f5f5
                    padding: 10px
                    font-size: 19px
                    text-decoration: none
                    border-bottom: 1px solid transparent
                    transition: border .3s

                a:hover
                    color: #f5f5f5
                    border-bottom: 1px solid #fff

                a.active
                    border-bottom: 1px solid #fff

            .menu-icon-li
                display: flex
                align-items: center
                a
                    border-bottom: 1px solid transparent !important
                    padding: 10px 0px 10px 10px
                    .menu-icon
                        width: 19px
                        height: 19px
                        transition: opacity 0.3s
                a:hover
                    .menu-icon 
                        opacity: 0.7

    .mobile-icon-container
        display: none
            
    
main
    .menu-space-container
        height: 75px

.footer-space-container
    height: 142px

footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
    padding: 40px 15px
    font-size: 16px
    text-align: center
    background-color: #4d4d4d
    // box-shadow: 0 0 3px #4d4d4d
    color: #f5f5f5
    
    ul 
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: center
        list-style-type: none
        margin: 0

        li
        
            a
                display: block
                margin: 8px
                color: #f5f5f5
                font-size: 16px
                line-height: 1.5em
                transition: opacity 0.3s

            a:hover
                color: #f5f5f5
                opacity: 0.8

        .menu-icon-li
            display: flex
            align-items: center
            a
                .menu-icon
                    width: 19px
                    height: 19px
                    transition: opacity 0.3s
            a:hover
                .menu-icon 
                    opacity: 0.8

    p
        margin: 6px 0 0
        font-size: 14px

img 
    max-width: 100%

sup
    position: relative
    top: 0em
    line-height: 0
    font-size: 12px

ul
    line-height: 150%
    margin: 0px 0px 10px 1.5em

hr
    height: 0px
    margin: 10px 0px
    color: transparent
    border-top: 0px
    border-bottom: 1px solid #4d4d4d

.center
    text-align: center

@media screen and (max-width: 991px)
    body
        font-size: 16px

    h2
        margin-bottom: 20px

    .btn
        margin-top: 20px

    .standard-padding
        padding: 40px 0px

    header
        .logo
            padding: 10px
            font-size: 22px
            line-height: 0.7em
            letter-spacing: 4px
            position: relative
            z-index: 102
        
            .logo-description 
                font-size: 9px
                color: #fff 
                letter-spacing: 5px

        nav
            padding: 10px

            ul
                position: absolute
                right: 0
                top: 100%
                padding: 10px 20px
                flex-direction: column
                align-items: flex-end
                background-color: #4d4d4d
                transition: transform 0.5s
                transform: translateY(-110%)
                box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)
                z-index: 100
                min-width: 220px

                li
                    a
                        font-size: 16px

                .menu-icon-li
                    a
                        padding: 10px
            
        .mobile-icon-container
            position: absolute
            z-index: 101
            background-color: #4d4d4d
            top: 0
            bottom:0
            left: 0
            right: 0
            display: flex
            justify-content: flex-end
            align-items: center
            padding: 10px 10px 9px

            .mobile-icon
                position: relative
                display: block
                width: 30px
                height: 100%
                cursor: pointer
                transition: opacity 0.3s

                .line1, .line2, .line3, .line4, .line5
                    width: 100%
                    height: 3px
                    border-radius: 3px
                    background-color: #fff
                    position: absolute
                    pointer-events: none
                .line1
                    top: calc(50%)
                    transform: translateY(calc(-50% - 11px))
                .line2
                    top: calc(50%)
                    transform: translateY(-50%)
                .line3
                    top: calc(50%)
                    transform: translateY(calc(-50% + 11px))
                .line4
                    width: 34px
                    height: 4px
                    display: none
                    top: calc(50% - 2px)
                    left: 50%
                    transform: translateX(-50%) rotate(45deg)
                .line5
                    width: 34px
                    height: 4px
                    display: none
                    top: calc(50% - 2px)
                    left: 50%
                    transform: translateX(-50%) rotate(-45deg)
            .mobile-icon:hover
                opacity: 0.8

    header.active
        ul
            transform: translateY(0%)
        .mobile-icon
            .line1, .line2, .line3
                display: none
            .line4, .line5
                display: block

    .loading.fullscreen-loading
        padding: 55px 15px 335px

    main
        .menu-space-container
            height: 55px

    footer
        ul
            flex-direction: column
            margin-bottom: 20px
            li
                a
                    margin: 2px
            .menu-icon-li
                justify-content: center

    .footer-space-container
        height: 335px
