.expand-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.5s, z-index 0.5s;
  background-color: rgba(0, 0, 0, 0.8705882353);
  overflow: hidden;
  z-index: -100;
}

.expand-image.active {
  opacity: 1;
  z-index: 9999;
}

.expand-image.hide {
  opacity: 0;
}

.expand-image-close {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.expand-image-container {
  position: absolute;
  top: 50px;
  left: 15px;
  right: 15px;
  bottom: 50px;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-image-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=imageframe.css.map */