.videos-container
    position: relative
    display: flex
    flex-wrap: wrap

    .card
        color: #f5f5f5
        flex: 0 0 25%
        padding: 5px
        position: relative
        width: 100%
        border: 0

        .card-box
            overflow: hidden
            position: relative
            box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25)
            border-radius: 4px

            img
                width: 100%
                object-fit: cover
                min-height: 180px
                transition: transform 0.6s

            p
                position: absolute
                bottom: 0px
                left: 0px
                right: 0px
                padding: 12px
                margin: 0
                background-color: rgba(0,0,0,.7)
                text-align: center
                font-size: 16px
        
            a
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                font-size: 0
                color: #f5f5f5

        .card-box:hover
            img
                transform: scale(1.1)

.videos-nav
    margin: 30px 0 0
    position: relative

    .numbers
        text-align: center
        // position: absolute
        // top:
        // left: 50%
        // transform: translateX(-50%)

    .page-arrow
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        display: block
        width: 30px
        height: 30px
        background-color: #4d4d4d
        border-radius: 4px
        color: #f5f5f5
        font-size: 0

        img
            width: 14px
            height: 14px
            position: absolute
            top: 50%
            transform: translate(-50%, -50%)

    .arrow-prev
        left: calc(50% - 52px)
        img
            transform: translate(-50%, -50%) rotate(180deg)
            left: calc(50% - 1px)
    .arrow-next
        left: calc(50% + 52px)
        img
            left: calc(50% + 1px)


@media screen and (max-width: 991px)
    .videos-container
        .card
            flex: 0 0 33.333%

@media screen and (max-width: 767px)
    .videos-container
        .card
            flex: 0 0 50%

@media screen and (max-width: 575px)
    .videos-container
        .card
            flex: 0 0 100%