.scores-container
    padding-bottom: 24px
    &.hide
        display: none

.scores-title
    background-color: #ededed
    padding: 8px 16px
    border-top-left-radius: 4px
    border-top-right-radius: 4px

.compositions-container
    border-left: 1px solid #ededed
    border-right: 1px solid #ededed
    border-bottom: 1px solid #ededed
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    .composition
        padding: 8px 16px
        display: flex
        flex-wrap: wrap
        text-align: center
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid #ededed
        h6
            font-size: 18px
            font-weight: 400
        p
            margin-bottom: 5px
            font-size: 14px
        .buttons
            display: flex
            justify-content: center
            a
                display: block
                background-color: #4d4d4d
                border-radius: 4px
                padding: 6px 14px
                color: #f5f5f5
                margin: 0 5px
                font-size: 16px
                transition: background-color 0.3s
                display: flex
                justify-content: center
                align-items: center
            a:first-of-type
                margin: 0 5px 0 0
            a:last-of-type
                margin: 0 0 0 5px
            a:hover
                background-color: #696969
    .composition:last-of-type
        border-bottom: 0px
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px

// @media screen and (max-width: 991px)

// @media screen and (max-width: 767px)

@media screen and (max-width: 575px)
    .scores-title
        text-align: center
    .compositions-container
        .composition
            flex-direction: column
            .buttons
                padding-top: 8px