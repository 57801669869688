.about-page
    .container
        display: flex
        .about-image
            flex: 0 0 294px
            padding-left: 30px
            img
                width: 264px

.events
    background-color: #fff

    .posters
        display: flex
        flex-wrap: wrap
        margin: 0 auto
        a
            img
                transition: opacity 0.6s
                opacity: 1
            img:hover
                opacity: 0.5

@media screen and (max-width: 575px)
    .about-page
        .container
            display: flex    
            flex-direction: column
            align-items: center
            .about-image
                flex: 0 0 auto
                padding: 20px 0 0