.about-page .container {
  display: flex;
}
.about-page .container .about-image {
  flex: 0 0 294px;
  padding-left: 30px;
}
.about-page .container .about-image img {
  width: 264px;
}

.events {
  background-color: #fff;
}
.events .posters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.events .posters a img {
  transition: opacity 0.6s;
  opacity: 1;
}
.events .posters a img:hover {
  opacity: 0.5;
}

@media screen and (max-width: 575px) {
  .about-page .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-page .container .about-image {
    flex: 0 0 auto;
    padding: 20px 0 0;
  }
}/*# sourceMappingURL=about.css.map */