* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #f5f5f5;
  color: #4d4d4d;
  font-size: 18px;
  font-family: "Liberation Serif", Cambria, Times New Roman, serif;
  overflow-x: hidden;
}

#root {
  position: relative;
  min-height: 100vh;
}

a {
  color: #4d4d4d;
  text-decoration: none;
}

a:hover {
  color: #696969;
}

img {
  display: block;
  max-width: 100%;
}

p {
  margin-bottom: 15px;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  padding: 0 15px;
}

.standard-padding {
  padding: 60px 0px;
}

.text-center {
  text-align: center;
}

.btn {
  display: inline-block;
  padding: 10px 50px 10px 20px;
  margin-top: 30px;
  background-color: #4d4d4d;
  border-radius: 4px;
  transition: background-color 0.3s;
  position: relative;
  color: #f5f5f5;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25);
}
.btn img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  transition: transform 0.5s;
}

.btn:hover {
  background-color: #696969;
  color: #f5f5f5;
}
.btn:hover img {
  transform: translate(50%, -50%);
}

h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

h4 {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
}

.loading {
  text-align: center;
  flex: 0 0 100%;
}
.loading img {
  display: block;
  margin: 0 auto;
  animation: loaderAnim 1.5s linear infinite;
}
@keyframes loaderAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading.loading-items {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  background-color: rgba(245, 245, 245, 0.7333333333);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.loading.fullscreen-loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 78px 15px 142px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 100;
}
header .logo {
  display: block;
  padding: 15px 15px 11px;
  text-transform: uppercase;
  font-family: "Noto Serif", serif;
  width: 260px;
  color: #fff;
  font-size: 28px;
  line-height: 0.7em;
  letter-spacing: 4px;
  text-decoration: none;
  transition: opacity 0.3s;
}
header .logo .logo-description {
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6666666667);
  font-size: 11px;
  color: #fff;
  letter-spacing: 6px;
  line-height: 1em;
}
header .logo:hover {
  opacity: 0.8;
}
header nav {
  padding: 15px 15px 14px;
}
header nav ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0;
}
header nav ul li a {
  display: inline-block;
  color: #f5f5f5;
  padding: 10px;
  font-size: 19px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.3s;
}
header nav ul li a:hover {
  color: #f5f5f5;
  border-bottom: 1px solid #fff;
}
header nav ul li a.active {
  border-bottom: 1px solid #fff;
}
header nav ul .menu-icon-li {
  display: flex;
  align-items: center;
}
header nav ul .menu-icon-li a {
  border-bottom: 1px solid transparent !important;
  padding: 10px 0px 10px 10px;
}
header nav ul .menu-icon-li a .menu-icon {
  width: 19px;
  height: 19px;
  transition: opacity 0.3s;
}
header nav ul .menu-icon-li a:hover .menu-icon {
  opacity: 0.7;
}
header .mobile-icon-container {
  display: none;
}

main .menu-space-container {
  height: 75px;
}

.footer-space-container {
  height: 142px;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 15px;
  font-size: 16px;
  text-align: center;
  background-color: #4d4d4d;
  color: #f5f5f5;
}
footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0;
}
footer ul li a {
  display: block;
  margin: 8px;
  color: #f5f5f5;
  font-size: 16px;
  line-height: 1.5em;
  transition: opacity 0.3s;
}
footer ul li a:hover {
  color: #f5f5f5;
  opacity: 0.8;
}
footer ul .menu-icon-li {
  display: flex;
  align-items: center;
}
footer ul .menu-icon-li a .menu-icon {
  width: 19px;
  height: 19px;
  transition: opacity 0.3s;
}
footer ul .menu-icon-li a:hover .menu-icon {
  opacity: 0.8;
}
footer p {
  margin: 6px 0 0;
  font-size: 14px;
}

img {
  max-width: 100%;
}

sup {
  position: relative;
  top: 0em;
  line-height: 0;
  font-size: 12px;
}

ul {
  line-height: 150%;
  margin: 0px 0px 10px 1.5em;
}

hr {
  height: 0px;
  margin: 10px 0px;
  color: transparent;
  border-top: 0px;
  border-bottom: 1px solid #4d4d4d;
}

.center {
  text-align: center;
}

@media screen and (max-width: 991px) {
  body {
    font-size: 16px;
  }
  h2 {
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 20px;
  }
  .standard-padding {
    padding: 40px 0px;
  }
  header .logo {
    padding: 10px;
    font-size: 22px;
    line-height: 0.7em;
    letter-spacing: 4px;
    position: relative;
    z-index: 102;
  }
  header .logo .logo-description {
    font-size: 9px;
    color: #fff;
    letter-spacing: 5px;
  }
  header nav {
    padding: 10px;
  }
  header nav ul {
    position: absolute;
    right: 0;
    top: 100%;
    padding: 10px 20px;
    flex-direction: column;
    align-items: flex-end;
    background-color: #4d4d4d;
    transition: transform 0.5s;
    transform: translateY(-110%);
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25);
    z-index: 100;
    min-width: 220px;
  }
  header nav ul li a {
    font-size: 16px;
  }
  header nav ul .menu-icon-li a {
    padding: 10px;
  }
  header .mobile-icon-container {
    position: absolute;
    z-index: 101;
    background-color: #4d4d4d;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 10px 9px;
  }
  header .mobile-icon-container .mobile-icon {
    position: relative;
    display: block;
    width: 30px;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  header .mobile-icon-container .mobile-icon .line1, header .mobile-icon-container .mobile-icon .line2, header .mobile-icon-container .mobile-icon .line3, header .mobile-icon-container .mobile-icon .line4, header .mobile-icon-container .mobile-icon .line5 {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #fff;
    position: absolute;
    pointer-events: none;
  }
  header .mobile-icon-container .mobile-icon .line1 {
    top: 50%;
    transform: translateY(calc(-50% - 11px));
  }
  header .mobile-icon-container .mobile-icon .line2 {
    top: 50%;
    transform: translateY(-50%);
  }
  header .mobile-icon-container .mobile-icon .line3 {
    top: 50%;
    transform: translateY(calc(-50% + 11px));
  }
  header .mobile-icon-container .mobile-icon .line4 {
    width: 34px;
    height: 4px;
    display: none;
    top: calc(50% - 2px);
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  header .mobile-icon-container .mobile-icon .line5 {
    width: 34px;
    height: 4px;
    display: none;
    top: calc(50% - 2px);
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }
  header .mobile-icon-container .mobile-icon:hover {
    opacity: 0.8;
  }
  header.active ul {
    transform: translateY(0%);
  }
  header.active .mobile-icon .line1, header.active .mobile-icon .line2, header.active .mobile-icon .line3 {
    display: none;
  }
  header.active .mobile-icon .line4, header.active .mobile-icon .line5 {
    display: block;
  }
  .loading.fullscreen-loading {
    padding: 55px 15px 335px;
  }
  main .menu-space-container {
    height: 55px;
  }
  footer ul {
    flex-direction: column;
    margin-bottom: 20px;
  }
  footer ul li a {
    margin: 2px;
  }
  footer ul .menu-icon-li {
    justify-content: center;
  }
  .footer-space-container {
    height: 335px;
  }
}/*# sourceMappingURL=style.css.map */